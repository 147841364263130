import React from 'react'

import Layout from "../components/layout"
import Post from "../components/blog/post/post"

const post = (props) => {
    let pageContext = props.pageContext.node;
    return (
        <Layout>
            <Post pageContext={pageContext}></Post>
        </Layout>
    )
}

export default post
