import React, { useLayoutEffect, useState } from 'react';
import Helmet from "react-helmet"
// import defaultProfilePicture from '../../../images/blog/profile.svg';
import defaultPostImg from '../../../images/blog/post-image.png';
import SEO from '../../shared/seo'
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { removeTag } from "../../../utils/utils";
import './post.scss';

const TABLET = 768;
const SMALL_ICON = 48;
const BIG_ICON = 64;

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Post = (props) => {
    let pageContext = props.pageContext;
    const [width] = useWindowSize();
    let size = width >= TABLET ? BIG_ICON : SMALL_ICON;
    let featured_media_source_url = pageContext.featured_media != null ? pageContext.featured_media.source_url : '';
    return (
        <section className="post-container">
            <SEO lang="es" path={`blog/${pageContext.slug}/`} description={removeTag(pageContext.excerpt,'p')} title={pageContext.title} meta={[
                {
                    property: `og:type`,
                    content: `article`,
                },
                {
                    property: `og:image`,
                    content: featured_media_source_url
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
					name: `twitter:title`,
					content: pageContext.title,
                },
                {
					name: `twitter:description`,
					content: removeTag(pageContext.excerpt,'p'),
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                },
            ]}
            />
            {
                pageContext.featured_media != null ?
                    <picture className="post-picture">
                        <img alt={pageContext.featured_media.alt_text} className="post-img" src={pageContext.featured_media.source_url}></img>
                    </picture> :
                    <picture className="post-picture">
                        <img alt={''} className="post-img" src={defaultPostImg}></img>
                    </picture>
            }
            <div className="post-img-caption-container">
                <a className="post-img-caption" href={pageContext.cc_featured_image_caption.source_url}>
                    {pageContext.cc_featured_image_caption.caption_text}
                </a>
            </div>
            <div className="post-heading">
                <h1 className="post-heading-title">{pageContext.title}</h1>
                <div>
                    <p className="post-heading-info"> Por <span className="post-heading-author">{pageContext.author.name}</span> | {pageContext.date} </p>
                </div>
            </div>
            <div className="post-content" dangerouslySetInnerHTML={{ __html: pageContext.content }} />
            <div className="post-rrss">
                <FacebookShareButton quote={pageContext.title} url={pageContext.link}>
                    <FacebookIcon size={size} round={true}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton className="post-rrss-button" url={pageContext.link} title={pageContext.title} via={pageContext.link}>
                    <TwitterIcon size={size} round={true}></TwitterIcon>
                </TwitterShareButton>
                <LinkedinShareButton className="post-rrss-button" title={pageContext.title} summary={pageContext.excerpt} url={pageContext.link} source={"SphereDevs"}>
                    <LinkedinIcon size={size} round={true}> </LinkedinIcon>
                </LinkedinShareButton>
            </div>
            <div className="post-author-container">
                <div className="post-author-profile">
                    <picture>
                        <img alt="foto de perfil" className="post-author-profile-img" src={pageContext.author.avatar_urls.wordpress_96.source_url} />
                    </picture>
                </div>
                <div className="post-author-info">
                    <h3 className="post-author-info-name">{pageContext.author.name}</h3>
                    <p className="post-author-info-bio">{pageContext.author.description}</p>
                    <a className="post-author-info-link" href={pageContext.author.url} target="_blank">
                        <LinkedinIcon size={SMALL_ICON} round={true}></LinkedinIcon>
                    </a>
                </div>
            </div>
            <Helmet>
                <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "url": "https://spheredevs.com/blog/${pageContext.slug}/",
                        "mainEntityOfPage": "https://spheredevs.com/blog/${pageContext.slug}/",
                        "headline": "${pageContext.title}",
                        "image": [
                          "${featured_media_source_url}"
                         ],
                        "datePublished": "${pageContext.full_date}",
                        "dateModified": "${pageContext.full_date}",
                        "author": {
                          "@type": "Person",
                          "name": "${pageContext.author.name}"
                        },
                        "publisher": {
                          "@type": "Organization",
                          "name": "SphereDevs",
                          "logo": {
                            "@type": "ImageObject",
                            "url": "https://res.cloudinary.com/spheredevs/image/upload/v1574382903/SphereDevs/logo-light.png"
                          }
                        }
                    }
                `}</script>
            </Helmet>
        </section>
    )
}
export default Post;